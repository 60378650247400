@import "animate";
@import "videojs";
@import "react-toastify/dist/ReactToastify.css";

$theme-color: #eb6a74;
$warning-color: #ff7900;
$theme-main-background: transparent;
$theme-main: $primary;
$blue-theme-secondary-hover: #c2e6ff;
$theme-main-dark: $primary-dark;

givingjoy-video-uploader,
givingjoy-video-player {
    display: block;
    font-size: 13px;
    & * {
        font-family: "Avenir LT Std", sans-serif;
    }
}

.MuiTooltip-tooltip {
    font-family: "Avenir LT Std", sans-serif;
    font-size: 12px !important;
}

.vjs-gratiphied-theme {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    background: transparent;
    transition: all 250ms;
}
.video-js .vjs-time-control {
    min-width: 4em;
}
.video-js .vjs-big-play-button {
    font-size: 4em;
}
.videoPlayerDimensions.video-js .vjs-tech {
    position: relative;
    max-height: 400px;
}
.recorder-container {
    background: #111;
    position: relative;
    text-align: center;
    .videoPlayerDimensions.video-js .vjs-tech {
        max-height: unset;
    }
    .videoPlayerDimensions {
        height: auto;
        min-height: unset;
    }
}
@media only screen and (min-width: 600px) {
    .recorder-container {
        min-width: 400px !important;
        .video-js .vjs-tech {
            height: auto;
        }
    }
}
.video-js.vjs-gratiphied-theme .vjs-control-bar {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 125%;
}
.view-video-mobile-container {
    .video-js.vjs-gratiphied-theme .vjs-control-bar {
        bottom: calc(env(safe-area-inset-top) + var(--warning-strip-height, 0px));
    }
}
.vjs-gratiphied-theme .vjs-big-play-button {
    width: 103px;
    height: 79px;
    -o-object-fit: contain;
    object-fit: contain;
    background: rgba(115, 133, 159, 0.3);
    border: none;
    line-height: 79px;
    top: calc(50%);
    left: calc(50% - 12px);
    border-radius: 30px;
    margin: -51.5px auto 0 -39.5px;
}
.vjs-gratiphied-theme .vjs-control-bar {
    height: 4em;
    background: hsla(0, 0%, 100%, 0.4);
}
.vjs-gratiphied-theme .vjs-button > span:before {
    line-height: 2.2;
    text-shadow: 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.5);
}
.vjs-gratiphied-theme .vjs-volume-bar {
    margin: 1.7em 0.45em;
}
.vjs-gratiphied-theme .vjs-time-control {
    line-height: 4em;
}
.vjs-gratiphied-theme .vjs-progress-holder,
.vjs-gratiphied-theme .vjs-volume-bar.vjs-slider-horizontal,
.vjs-gratiphied-theme .vjs-slider-horizontal .vjs-volume-level {
    height: 0.5em;
    border-radius: 0.3em;
}
.vjs-gratiphied-theme .vjs-volume-bar.vjs-slider-horizontal {
    display: none;
}
.vjs-gratiphied-theme .vjs-play-progress,
.vjs-gratiphied-theme .vjs-volume-level {
    background: white;
}
.vjs-gratiphied-theme .vjs-play-progress:before,
.vjs-gratiphied-theme .vjs-volume-level:before,
.vjs-icon-circle:before,
.vjs-seek-to-live-control .vjs-icon-placeholder:before {
    content: "";
}
.vjs-gratiphied-theme .vjs-load-progress,
.vjs-gratiphied-theme .vjs-mouse-display,
.vjs-gratiphied-theme .vjs-load-progress div,
.vjs-gratiphied-theme .vjs-play-progress.vjs-slider-bar {
    border-radius: 0.3em;
}
.vjs-gratiphied-theme .vjs-play-progress.vjs-slider-bar {
    background: $primary;
}
.vjs-gratiphied-theme .vjs-current-time,
.vjs-time-divider,
.vjs-gratiphied-theme .vjs-duration,
.vjs-no-flex .vjs-duration {
    display: block;
}
.vjs-gratiphied-theme .vjs-poster {
    background-color: transparent;
}
.video-js.vjs-gratiphied-theme .vjs-time-divider,
.video-js.vjs-gratiphied-theme .vjs-time-control {
    padding-left: 0;
    padding-right: 0;
    min-width: 5px;
}
.video-js.vjs-gratiphied-theme.custom-controls {
    .vjs-control-bar {
        background: transparent;
        z-index: 2;
        .vjs-current-time.vjs-time-control {
            padding-left: 10px;
        }
        .vjs-play-progress.vjs-slider-bar:before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $primary;
            transition: all 250ms;
        }
    }
    .vjs-progress-control.vjs-control:hover {
        .vjs-play-progress.vjs-slider-bar:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background-color: $primary;
        }
    }
}
.video-js.vjs-gratiphied-theme.controls-80 {
    .vjs-control-bar {
        bottom: 80px;
    }
}
.video-picker-container {
    transition: all 250ms;
    background: $theme-main-background;
    .primary-button .fas {
        color: white;
    }
    .secondary-button .fas {
        color: $theme-main;
    }
}
.video-picker-choices {
    background: $theme-main-background;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.primary-button,
.secondary-button {
    min-width: 60px;
    padding: 14px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}
.video-picker-choices-container .primary-button.vertical,
.video-picker-choices-container .secondary-button.vertical {
    width: 160px;
}
.vjs-control {
    width: 3em !important;
}
@media only screen and (min-width: 800px) {
    .primary-button,
    .secondary-button {
        width: 152px;
    }
}
.primary-button.vertical i,
.secondary-button.vertical i {
    padding-bottom: 10px;
    font-size: 20px;
}
.primary-button {
    background: $theme-main;
    color: white;
}
.primary-button.recording,
.primary-button.record {
    background: $theme-main;
    color: white;
    min-width: 46px !important;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    z-index: 20;
    &.preview-mode {
        padding: 7px 0;
    }
    .fa-times {
        color: $theme-main;
        font-size: 2rem;
    }
}
.primary-button.recording {
    background: transparent;
    color: $theme-main;
}
.secondary-button {
    color: $theme-main;
    border: 1px solid $theme-main;
}
.record-btn-container,
.recording-btn-container {
    border: 4px solid white;
    position: relative;
    border-radius: 50%;
    transition: all 300ms ease-in-out;
    .MuiCircularProgress-colorPrimary {
        color: white !important;
        z-index: 1;
        cursor: pointer;
    }
}
.circular-record-timer {
    position: absolute;
    left: -4px;
    top: -4px;
}
.duration-warning-container {
    width: 200px;
    box-sizing: border-box;
    position: relative;
    color: $theme-main;
    border: 1px solid $theme-main;
    min-width: 100px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    .fa-exclamation-triangle {
        color: $warning-color;
    }
    #progress-bar {
        z-index: -1;
        border-radius: 5px;
        background: $blue-theme-secondary-hover;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: width 4s linear;
    }
}
.duration-warning-desktop,
.tab-warning-mobile {
    position: absolute;
    bottom: 80px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0.05em 0.05em 0.1em #000;
}
.vjs-playback-rate-value,
.vjs-current-time-display {
    text-shadow: 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.5);
}
.tab-warning-mobile {
    top: 100px;
    bottom: unset;
}
.show-view {
    display: block;
}
.hide-view {
    display: none;
}
.d-flex {
    display: flex;
}
.pt-3 {
    padding-top: 1rem;
}
.m-auto {
    margin: auto;
}
.menu {
    position: relative;
    background: $theme-main-background;
}
.menu-button {
    margin: 1rem auto;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.ml-1 {
    margin-left: 0.25rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.mr-3 {
    margin-right: 1rem;
}
.mt-3 {
    margin-top: 1rem;
}
.ml-3 {
    margin-left: 1rem;
}
.w-100 {
    width: 100%;
}
.player-height:not(.vjs-fluid) {
    max-height: 400px;
}
.display-flex {
    display: flex;
}
.rounded-corner-player {
    border-radius: 18px;
    overflow: hidden;
}
@media only screen and (max-width: 500px) {
    .rounded-corner-player {
        border-radius: 8px;
    }
}
.video-picker input {
    visibility: hidden;
    position: absolute;
    width: 50%;
}
.video-picker-choices {
    height: 400px;
}
#webRTCVideo .vjs-control-bar {
    display: flex !important;
    background: transparent;
    border: none;
}
#webRTCVideo .vjs-control-bar .vjs-control {
    display: none !important;
}
.w-550px {
    width: 550px;
}
.recording-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding: 2rem;
    z-index: 7;
    .recording-duration-label {
        padding: 5px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }
}
.teleprompter-duration-label {
    font-size: 60px;
    text-align: right;
    position: absolute;
    left: -8px;
    top: 0;
    border-radius: 10px;
    transform: translateX(-100%);
    &.mobile {
        font-size: 19px;
        transform: translateX(0);
        text-align: left;
        left: 5px;
        top: 4px;
        z-index: 8;
    }
}
.recorder-progress-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
}
#webRTCVideo .vjs-control-bar .durationClassLabel {
    display: inline-block !important;
    font-size: 15px;
    color: white;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.3);
}
#webRTCVideo .vjs-control {
    width: 4em !important;
}
#webRTCVideo .vjs-time-divider {
    display: none;
}
.stop-recording-button {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: $primary;
    margin-left: 11px;
    margin-top: -4px;
}
.videoPlayerDimensions {
    width: 100%;
    height: 400px;
    min-height: 400px;
}
.skip-back::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f04a";
}
.skip-forward::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f04e";
}
.display-flex > div {
    position: relative;
    margin: 1rem auto;
}
.upload-info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
}
.thumbnail-btn-overlay {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
}
.upload-completion-label {
    color: white;
    background: transparent;
    padding: 10px 0;
}
.recorder-canvas {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: transform 500ms ease-in-out;
    z-index: 5;
}
.teleprompter-audiobar {
    text-align: center;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    &.mobile {
        transform: translateX(0);
        text-align: left;
        left: 70px;
        top: 4px;
        z-index: 8;
    }
}
.overlay-video {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    height: calc(100% - 85px);
    &.mobile {
        z-index: 6;
        left: 0;
        top: 0;
    }
}
.progress-container {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    -o-object-fit: contain;
    object-fit: contain;
}
.circle-background,
.circle-progress {
    fill: none;
}
.circle-background {
    stroke: #ddd;
}
.circle-progress {
    stroke: $theme-main;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.circle-text {
    font-size: 2em;
    font-weight: bold;
    fill: $theme-main;
}
.loading-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    text-align: center;
    transform: translate(-50%, -50%);
    .text {
        color: white;
        background: rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 5px;
    }
}
.container.container-max-width.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.error-info-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -100%);
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    width: 90%;
}
.error-info-container {
    .error-message {
        text-align: center;
        padding-bottom: 5px;
        padding-top: 5px;
        font-weight: bold;
    }
}

@media only screen and (min-width: 800px) {
    .retry-button-container {
        width: 152px !important;
    }
}

.retry-button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100px;
    margin: 0 auto;
    margin-top: 1rem;
    .retry-button {
        i,
        span {
            font-size: 15px;
            font-weight: bold;
        }
        i {
            padding-right: 8px;
        }
    }
}
.confirm-native-record {
    width: 60px;
    margin: auto;
    margin-top: 0.5rem;
    padding: 8px 0;
}
.MuiDialogTitle-root,
.MuiDialogContent-root {
    margin: 0 auto !important;
}
.MuiChip-root.MuiChip-colorPrimary,
.MuiTypography-root.MuiFormControlLabel-label,
.MuiButtonBase-root.MuiMenuItem-root {
    font-family: inherit;
}
.media-device-chip {
    max-width: 240px;
    border-color: transparent;
    color: white;
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
        border-color: transparent;
        color: white;
        font-size: 1rem;
        padding-left: 5px;
        padding-top: 6px;
        padding-bottom: 6px;
        max-width: 72px;
        &:active {
            outline: none;
        }
    }
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
        color: white;
    }
    &.hide-caret .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
        color: transparent;
    }
}
.select-with-icon {
    border: 1px solid #707070 !important;
    color: white;
    border-radius: 20px;
    min-width: 40px;
    height: 35px;
    cursor: pointer;
    > svg {
        height: 30px;
    }
    svg.MuiChip-deleteIcon {
        color: white;
    }
    .MuiInputBase-formControl {
        font-family: inherit;
    }
}
.MuiSwitch-root.MuiSwitch-sizeMedium {
    .Mui-checked {
        color: $theme-main;
    }
    .MuiSwitch-track {
        background-color: $theme-main !important;
    }
}
.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.facial-touchup-slider {
    width: 100px !important;
    .MuiSlider-rail {
        color: #cccccc;
        height: 8px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    }
    .MuiSlider-track {
        color: $theme-main;
        height: 7px;
    }
    .MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary {
        color: #333333;
        border: 2px solid #b5b5b5;
    }
}

.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.zoom-slider {
    width: 100px !important;
    .MuiSlider-rail {
        color: #707070;
        height: 2px;
    }
    .MuiSlider-track {
        color: $theme-main-dark;
        height: 1px;
    }
    .MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary {
        color: #333333;
        border: 2px solid #b5b5b5;
    }
}
.MuiSvgIcon-root.MuiSvgIcon-colorPrimary {
    color: $theme-main;
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.video-end-message {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    &.dark-bg {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .video-end-message-container {
        display: table-cell;
        vertical-align: middle;
    }
    .video-end-message-full-screen {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
    }
}

// Theme specific colors here //
// Green theme START //
$green-theme-main-background: #ecf4ec;
$green-theme-main: #3fc741;
$green-theme-main-dark: #24b927;
$green-theme-secondary-hover: #cbebcb;

.video-picker-container.green-theme {
    background: $green-theme-main-background;
    .video-picker-choices {
        background: $green-theme-main-background;
    }
    .primary-button {
        background: $green-theme-main;
        &:hover {
            background: $green-theme-main-dark;
        }
    }
    .secondary-button {
        border: 1px solid $green-theme-main;
        color: $green-theme-main;
        &:hover {
            background: $green-theme-secondary-hover;
        }
        .fas {
            color: $green-theme-main;
        }
    }
    .menu {
        background: $green-theme-main-background;
    }
    .circle-progress {
        stroke: $green-theme-main;
    }
    .circle-text {
        fill: $green-theme-main;
    }
    .duration-warning-container {
        color: $green-theme-main;
        border: 1px solid $green-theme-main;
        #progress-bar {
            background: $green-theme-secondary-hover;
        }
    }
    .react-multiple-carousel__arrow {
        background: $green-theme-main;
        &:hover {
            background: $green-theme-main-dark;
        }
    }
}
// Green theme END //
// VidInvite orange-purple START //
$vidinvite-theme-main-background: linear-gradient(to bottom right, #facaca, #ffcad2);
$vidinvite-theme-main: #ff0202;
$vidinvite-theme-main-dark: #e40b0b;
$vidinvite-theme-secondary-hover: #ffa0a0;

.video-picker-container.vidinvite-theme {
    background: $vidinvite-theme-main-background;
    .video-picker-choices {
        background: $vidinvite-theme-main-background;
    }
    .primary-button {
        background: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-main-dark;
        }
    }
    .secondary-button {
        border: 1px solid $vidinvite-theme-main;
        color: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-secondary-hover;
        }
        .fas {
            color: $vidinvite-theme-main;
        }
    }
    .menu {
        background: $vidinvite-theme-main-background;
    }
    .circle-progress {
        stroke: $vidinvite-theme-main;
    }
    .circle-text {
        fill: $vidinvite-theme-main;
    }
    .duration-warning-container {
        color: $vidinvite-theme-main;
        border: 1px solid $vidinvite-theme-main;
        #progress-bar {
            background: $vidinvite-theme-secondary-hover;
        }
    }
    .react-multiple-carousel__arrow {
        background: $vidinvite-theme-main;
        &:hover {
            background: $vidinvite-theme-main-dark;
        }
    }
}
// Vidinvite orange-purple theme END //
.audiobar {
    background: rgba(119, 119, 119, 0.5);
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.5rem;
    box-sizing: border-box;
}

.playing_bar {
    display: inline-block;
    background: white;
    width: 30%;
    height: 100%;
    animation: up-and-down 1.3s ease infinite alternate;
}

.playing_bar1 {
    height: 60%;
}

.playing_bar2 {
    height: 30%;
    animation-delay: -2.2s;
}

.playing_bar3 {
    height: 1%;
    animation-delay: -3.7s;
}

.silence_bar {
    display: inline-block;
    background: #dd4f5d;
    width: 30%;
    height: 100%;
}

.silence_bar1 {
    height: 1%;
}

.silence_bar2 {
    height: 1%;
}

.silence_bar3 {
    height: 1%;
}

.videostop {
    width: 2rem;
    height: 2rem;
}

.background-settings {
    position: relative;
    width: 168px;
}

.select-image-button {
    cursor: pointer;
}

.select-screenshare-button {
    cursor: pointer;
}

.line-symbol {
    position: relative;
    width: 1px;
    height: 75%;
    background: #777;
    margin-right: 7px;
    top: 8px;
}

.line-symbol::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    width: 10px;
    height: 1px;
    background: #777;
    transform: translateY(-50%);
}

.line-symbol-video-audio {
    position: relative;
    width: 1px;
    height: 72%;
    background: #eee0dc;
    margin-right: 10px;
    margin-top: 5px;
}

.line-symbol-video-audio::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 10px;
    height: 1px;
    background: #eee0dc;
    transform: translateY(-50%);
}

.audio-video-settings {
    padding-right: 5px;
    max-width: 60px;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    button {
        font-size: 12px;
        line-height: 20px;
        background: transparent;
        border: none;
        cursor: pointer;
        color: #fff;
        &.selected {
            color: #dd4f5d;
        }
    }
}

.video-settings-drawer {
    .MuiPaper-root {
        z-index: 1220;
    }
}

.profile-image-popup {
    .MuiDialogContent-root {
        overflow: hidden !important;
    }
}

.image-background-popup {
    background: #333;
    // transition: height 300ms ease-in-out;
    overflow: hidden;
    .text-container {
        padding-left: 5px;
        padding-right: 5px;
    }
    .bg-list-container {
        height: 100%;
        max-height: calc(100vh - 360px);
        overflow: auto;
        &.mobile {
            max-height: calc(100vh - 500px);
        }
    }
    .image-container {
        overflow: hidden;
        color: #333;

        .image-grid-item {
            margin-bottom: 15px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            max-width: 225px;
            width: 100%;
            height: 150px;
            background-position: center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            &:hover {
                border: 5px solid white;
            }
            img {
                width: 225px;
                height: 150px;
                object-fit: cover;
            }
            svg.position-absolute {
                bottom: 5px;
                right: 5px;
            }
            .delete-background {
                right: 5px;
                top: 5px;
                display: inline-flex;
                svg {
                    fill: $primary;
                }
            }
        }
        .image-grid-item.selected {
            border: 5px solid #dd4f5d;
        }
        .custom-background-tile {
            position: relative;
            max-width: 225px;
            height: 150px;
            cursor: pointer;
            background-color: #444;
            &.add-background-tile {
                background-image: url("../../public/icons/group.svg");
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: auto !important;
            }
            &:hover {
                background-color: #555;
            }
            i {
                position: absolute;
                color: white;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-weight: 500;
            }
            &.loading {
                cursor: not-allowed;
                background-color: #444;
                &:hover {
                    border: 5px solid transparent;
                }
            }
        }
    }
}

.image-upload-background-popup {
    .file-drop-container {
        padding: 3rem !important;
    }
}

@keyframes up-and-down {
    10% {
        height: 30%;
    }

    30% {
        height: 100%;
    }

    60% {
        height: 50%;
    }

    80% {
        height: 75%;
    }

    100% {
        height: 60%;
    }
}
