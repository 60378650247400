#root {
    margin: 0 auto;
    width: 100%;
    min-height: 80vh;
    position: relative;
    background-color: #333;
}

.inset-padding-top {
    padding-top: env(safe-area-inset-top) !important;
}
